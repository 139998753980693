import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const AgentDashbord = () => {
  return (
    <>
      <Container>
        <Row style={{ marginTop: "50px" }}>
          <Col>
            <div className="text-center hedingDiv">
              <h1 className="text-center ">Dashboard</h1>
              <p>
                Our philosophy is simple — create a team of diverse, passionate
                people and <br /> foster a culture that empowers you to do you
                best work.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AgentDashbord;
